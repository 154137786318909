import { createContext, useEffect, useContext, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../../contexts/auth-context";
import queuesLogo from "../../assets/logo_1x.png";
import whLogo2 from "../../assets/Waffle_House_monogram.png";
import gtLogo from "../../assets/gtLogo.svg";
import Icon from "../atoms/Icon";
import { getChanges, getQueuesAPI } from "../../util";
import moment from "moment";
import Modal from "bootstrap/js/dist/modal";

export const LoadingContext = createContext();

export default function Nav() {
  const authCtx = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const currentPath = useLocation().pathname;

  let modal_ = null;

  const showReports = authCtx.authLocations.some(location => location.collection === 'waffleHouse');

  // const [lastLogged, setLastLogged] = useState(null);

  const links = [
    {
      url: "locations",
      icon: "geo-fill",
      label: "Locations",
    },
    ...(showReports ? [{
      url: "reports",
      icon: "clipboard2-data",
      label: "Reports",
    }] : []),
  ];

  useEffect(() => {
    if (!authCtx.loggedIn && currentPath == "/") {
      navigate("/auth");
    } else if (authCtx.loggedIn && currentPath == "/") {
      navigate("/locations");
    }
  }, [authCtx.loggedIn, currentPath, navigate]);

  const logout = () => {
    getQueuesAPI(
      "logoutFrontEnd",
      {
        email: authCtx.email,
      },
      (data) => {
        authCtx.logout();
        navigate("/auth");
      }
    );
  };

  useEffect(() => {
    modal_ = new Modal(document.getElementById("changes"));
    if (authCtx.loggedIn) {
      fetch("https://api.queuesapp.com/lastLoggedInFrontEnd", {
        headers: { "Content-Type": "application/json", token: authCtx.token },
        method: "POST",
        body: JSON.stringify({ email: authCtx.email }),
      })
        .then((response) => response.json())
        .then((data) => {
          let lastLogged = moment.unix(data.msg);
          let changes = getChanges("");
          if (lastLogged && lastLogged < moment(changes[0].date)) {
            modal_.show();
          }
        });
    }
  }, [authCtx.loggedIn]);

  return (
    <>
      <div className={"modal modal-lg"} id="changes">
        <div className="modal-dialog">
          <div className="modal-content p-3">
            <div className="p-4">
              {getChanges("").map((x) => (
                <div className="mb-5">
                  <p className="h3">
                    v{x.versionName} ({x.date})
                  </p>
                  <hr />
                  <div className="mt-3">
                    {x.changes
                      .filter((w) => w.type === "feature")
                      .map((y) => (
                        <div className="d-flex mb-1">
                          <Icon name="dot" />
                          <span className="badge mx-2">{y.type}</span>
                          {y.description}
                        </div>
                      ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <LoadingContext.Provider value={[loading, setLoading]}>
        <div className="nav">
          <div className="container">
            {authCtx.loggedIn ? (
              <div className="dropdown text-center">
                <button
                  data-bs-toggle="dropdown"
                  data-bs-target="#auth"
                  className="btn mb-3 dropdown-toggle">
                  <img
                    alt=""
                    src={authCtx.authLocations[0].collection === "waffleHouse" ? whLogo2 : gtLogo}
                    className="rounded-circle me-2"
                    width={30}
                    height={30}
                  />
                  <span className="ms-2">{authCtx.firstName}</span>
                </button>
                <div id="auth" className="dropdown-menu">
                  <button onClick={logout} className="dropdown-item">
                    Log Out
                  </button>
                </div>
              </div>
            ) : (
              <Link to="/auth" className="btn w-100 mb-3">
                <Icon name="person-fill" className="me-2" />
                Log In
              </Link>
            )}
            <Link to="/" style={{ color: "#53b8aa" }} className="logo">
              <img alt="" width="150" src={queuesLogo} />
            </Link>
            <div className="">
              {authCtx.loggedIn && (
                <>
                  {links.map((x) => (
                    <Link
                      key={x.label}
                      className={
                        "nav-item-custom " +
                        (currentPath === "/" + x.url ? "active" : "")
                      }
                      to={"/" + x.url}>
                      <Icon name={x.icon} className="me-3" />
                      {x.label}
                    </Link>
                  ))}
                  <hr />
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="mailto:support@queues.com"
                    className="nav-item-custom">
                    <Icon name="question-circle" className="me-3" />
                    Support
                  </a>
                  <a
                    data-bs-target="#changes"
                    data-bs-toggle="modal"
                    className="nav-item-custom">
                    <Icon name="asterisk" className="me-3" />
                    Changelog
                  </a>
                  {}
                </>
              )}
            </div>
          </div>
        </div>
        <Outlet />
      </LoadingContext.Provider>
    </>
  );
}
