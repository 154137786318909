import React, { useState, useRef, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import 'chartjs-adapter-moment';

const BarChart = ({ data }) => {

  const [dataValues, setDataValues] = useState(data)

  const getIntervalKey = (date, resolution) => { // Add resolution parameter
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const hour = date.getHours();
    const minute = Math.floor(date.getMinutes() / resolution) * resolution;
    return new Date(year, month, day, hour, minute).toISOString();
  };



  const [chartResolution, setChartResolution] = useState(5);


  const resolutions = [5, 10, 15, 30, 60];


  const increaseWidth = () => {
    const currentIndex = resolutions.indexOf(chartResolution);
    if (currentIndex < resolutions.length - 1) {
      setChartResolution(resolutions[currentIndex + 1]); // Update state
      updateDataMap(resolutions[currentIndex + 1]); // Pass new resolution as argument
    }
  };

  const decreaseWidth = () => {
    const currentIndex = resolutions.indexOf(chartResolution);
    if (currentIndex > 0) {
      setChartResolution(resolutions[currentIndex - 1]); // Update state
      updateDataMap(resolutions[currentIndex - 1]); // Pass new resolution as argument
    }
  };


  const updateDataMap = (resolution = chartResolution) => { // Use resolution parameter or current state
    const dataMap = new Map();

    var sum = 0;

    data.forEach(item => {
      const date = new Date(item.dateTime);
      const key = getIntervalKey(date, resolution); // Use resolution from argument

      sum += item.mins;

      if (!dataMap.has(key)) {
        item.mins = Math.round(sum / resolution); // Use resolution from argument
        sum = 0;
        dataMap.set(key, item);
      }
    });

    setDataValues(Array.from(dataMap.values()));
  };


  const maxValue = Math.max(...dataValues.map(item => item.mins));



  var barWidth = chartResolution;

  if (dataValues.length < 100) {
    barWidth += 3
  }

  const chartContainerWidth = dataValues.length * barWidth;

  const chartyData = {
    labels: dataValues.map(item => item.dateTime),
    datasets: [
    ],
  };

  const chartData = {
    labels: dataValues.map(item => item.dateTime),
    datasets: [
      {
        label: ` Average wait`,
        data: dataValues.map(item => item.mins),
        backgroundColor: dataValues.map(item => item.mins >= 10 ? '#B22626' : '#53b8aa'),
        barThickness: barWidth,
        borderRadius: 2.5,
      },
    ],
  };

  const yAxisChartOptions = {
    scales: {
      x: {
        display: false
      },
      y: {
        title: {
          display: true,
          text: 'Minutes'
        },
        grid: {
          display: false,
        },
        ticks: {
          precision: 0,
          beginAtZero: true,
          callback: function (value) {
            if (value % 1 === 0) {
              return value;
            }
          },
        },
        max: maxValue < 10 ? 10 : maxValue,
      }

    },
    responsive: true,
    maintainAspectRatio: false,

    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const mainChartOptions = {
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'hour',
          displayFormats: {
            hour: 'h:mm a'
          },
        },
        grid: {
          display: false,
        },
        ticks: {
          source: 'auto',
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0,
        }
      },
      y: {
        display: false
      }

    },
    responsive: true,
    maintainAspectRatio: false,

    plugins: {
      legend: {
        display: false,
      },
    },
  };


  const scrollRef = useRef(null);

  useEffect(() => {

    updateDataMap()

    const scrollElement = scrollRef.current;
    if (scrollElement) {
      const maxScrollLeft = scrollElement.scrollWidth - scrollElement.clientWidth;
      let start = null;

      const step = (timestamp) => {
        if (!start) start = timestamp;
        const progress = timestamp - start;
        const d = Math.min(progress / 2000, 1);
        scrollElement.scrollLeft = maxScrollLeft * d;

        if (progress < 2000) {
          requestAnimationFrame(step);
        }
      };

      requestAnimationFrame(step);
    }
  }, []);

  return (
    <div style={{
      paddingRight: '24px',
      paddingLeft: '24px',
      paddingTop: '8px',
      paddingBottom: '8px',
      borderRadius: '15px', // Fixed space issue
      marginTop: '16px',
      marginBottom: '16px',
      justifyContent: 'center', // Center the input inside the div
      alignItems: 'center', // Vertically center the input
      backgroundColor: 'white',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' // Elevation effect
    }}>

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '16px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ fontSize: '24px', marginLeft: '40px', marginBottom: '16px' }}>Average Wait Time History</p>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            marginRight: '16px'
          }}
        >
          <p style={{ fontSize: '16px', margin: '0 20px' }}>Bar Scale: {chartResolution} mins.</p>
          <button
            style={{ marginRight: '5px' }}
            className="btn border-1"
            onClick={increaseWidth}
          >
            +
          </button>
          <button
            className="btn border-1"
            onClick={decreaseWidth}
          >
            -
          </button>
        </div>
      </div>

      <div style={{ height: '380px', width: '80px', position: 'absolute' }}>
        <Bar data={chartyData} options={yAxisChartOptions} />
      </div>

      <div style={{ width: 'calc(100% - 40px)', overflowX: 'scroll', marginLeft: '40px' }} ref={scrollRef}>
        <div style={{ minWidth: `${chartContainerWidth * 2}px`, height: '400px' }}>
          <Bar data={chartData} options={mainChartOptions} />
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <p style={{ fontSize: '12px' }}>Time</p>
      </div>


    </div>
  );
};

export default BarChart;
