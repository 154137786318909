
import logo from "../../assets/gtLogo.svg";
import { Link } from "react-router-dom";

export default function SmallBusinessLocationCard({ item, waitTime, className = "" }) {
    return (
        <Link
            to={`../smallBusinessLocation/${item.collection}`}
            className="col fw-bold"
            >
            <div className="location-card shadow p-4">
                <div className="d-flex mb-2">
                    <img
                        alt=""
                        src={logo}
                        className="rounded-circle me-3"
                        width={40}
                        height={40}
                    />
                    
                    <div className="h3">{item.name}</div>
                </div>
                <hr />
                <div className="">
                    <div>Current Wait:</div>
                    <div
                        style={{ fontSize: "4rem" }}
                        className={
                            "fs-italic text-center" +
                            (waitTime > 9 ? " text-warning" : "")
                        }
                    >
                        {waitTime} mins
                    </div>
                </div>
            </div>
        </Link>
    );
}
