import {
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import { Link, useParams } from "react-router-dom";
import moment from "moment-timezone";
import AuthContext from "../contexts/auth-context";
import { ClipLoader } from "react-spinners";
import BarChart from '../components/atoms/BarChart';
import OperationalHours from "./operationalHours";
import SpecialHours from "./specialHours";
import gtLogo from "../assets/gtLogo.svg";
import AnalyticsCard from "../pages/AnalyticsCard";

export const HoveredContext = createContext();
export const ExpandedContext = createContext();
export const DataContext = createContext();

moment.tz.setDefault("America/New_York");

export default function SmallBusinessLocation() {

  const { id } = useParams();

  const [currentId, setCurrentId] = useState(id);
  const authCtx = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [currentTime, setCurrentTime] = useState(moment(new Date()).startOf("day").toDate());

  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState([])
  const [openingHours, setOpeningHours] = useState([])
  const [closingHours, setClosingHours] = useState([])

  const onChangeCurrentTime = (e) => {
    const inputDate = moment(e.target.value, "YYYY-MM-DD", true);

    if (inputDate.isValid()) {
      const today = moment();
      if (inputDate.isAfter(today)) {
        setCurrentTime(today.toDate());
      } else {
        setCurrentTime(inputDate.toDate());
      }
      getOperationalHours();
    }
  };

  useEffect(() => {
    if (currentTime) {
      getOperationalHours(); 
    }
  }, [currentTime]);

  const getOperationalHours = () => {
    setLoading(true);

    fetch("https://api.queuesapp.com/get-hours-dashboard", {
      headers: {
        "Content-Type": "application/json",
        token: authCtx.token,
      },
      method: "POST",
      body: JSON.stringify({
        collection: currentId
      }),
    })
      .then(response => response.json())
      .then(data => {
        setOpeningHours(data["openingHours"]);
        setClosingHours(data["closingHours"]);
        getData(data["openingHours"], data["closingHours"]);
      });
  };

  const getData = (freshOpeningHours, freshClosingHours) => {
    setData({ occData: [] })
    setChartData([])

    console.log("CURRENT TIME: ", moment(currentTime).format("yyyy-MM-DD"))

    fetch("https://api.queuesapp.com/get-data", {
      headers: {
        "Content-Type": "application/json",
        token: authCtx.token,
      },
      method: "POST",
      body: JSON.stringify({
        date: moment(currentTime).format("yyyy-MM-DD"),
        collection: currentId
      }),
    })
      .then(response => response.json())
      .then(data => {

        console.log("DATA LENGTH: ", data.length)

        var tempChartData = [];
        const currentDay = new Date().getDay();

        for (let i = 0; i < data.length; i++) {
          const hour = new Date(data[i]['time']).getHours();
          if (freshOpeningHours[currentDay] <= hour && hour <= freshClosingHours[currentDay]) {
            tempChartData.push({ dateTime: data[i]['time'], mins: data[i]['wait time'] });
          }
        }
        setLoading(false);

        setData({ occData: data });
        setChartData(tempChartData);

        console.log(tempChartData)
      });
  };


  useEffect(() => {
    if (authCtx.loggedIn) {
      console.log("GETTING HOUSRS")
      getOperationalHours();
    }
  }, []);

  return (
    <>
      {authCtx.loggedIn ? (
        <div>
          <div style={{ backgroundColor: 'rgba(83, 184, 170)', color: 'white', textAlign: 'left', padding: '24px', fontSize: '28px', fontWeight: 'bold' }}>
            Wait Analytics
          </div>

          <div className='p-4' style={{ backgroundColor: 'rgba(83, 184, 170, 0.15)' }}>

            <div className="d-flex mb-2 align-items-center">
              <img
                alt=""
                src={gtLogo}
                className="rounded-circle me-3"
                width="50"
                height="50"
                style={{ backgroundColor: 'white', padding: '4px', border: "2px solid black", boxSizing: 'border-box' }}
              />

              <div className="h3">
                {authCtx.authLocations.find(item => item.collection === currentId).name}
              </div>
            </div>

            <div className='elevated-card' style={{ width: '400px' }}>
              <input
                style={{
                  borderRadius: '10px',
                  border: 'none',
                  outline: 'none',
                  width: '100%',
                }}
                onChange={onChangeCurrentTime}
                type="date"
                className="form-control"
                value={moment(currentTime).format("yyyy-MM-DD")}
                max={moment().format("yyyy-MM-DD")}
              />
            </div>


            <div
              style={{
                width: "100%",
                height: "550px",
                position: 'relative',
                overflowY: 'auto'
              }}
            >
              {loading ? (
                <div style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)'
                }}>
                  <ClipLoader size={150} color="rgba(83, 184, 170)" />
                </div>
              ) : (
                <BarChart data={chartData} />
              )}
            </div>

            {
              loading ? (null) : (<AnalyticsCard data={chartData} />)
            }





            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', minWidth: '1200px' }}>
              {
                openingHours.length != 0 && closingHours.length != 0 ? (
                  <div style={{ marginRight: '24px' }}><OperationalHours openingHours={openingHours} closingHours={closingHours} currentId={currentId} /></div>
                ) : null
              }
              <div> <SpecialHours currentId={currentId} /></div>
            </div>

          </div>

        </div>
      ) : (
        <div className="p-4">
          <Link to={"/auth"}>
            Not Authorized. Please Log-In with given credentials or contact
            support.
          </Link>
        </div>
      )}
    </>
  );
}

//          ******** DROP-DOWN FRAGMENT********
// 
// <div className="d-flex justify-content-between py-3 sticky-top">
//   <div className="input-group w-75">
//     <div className="btn-group">
//       <button className="btn border-0" onClick={() => getOperationalHours()}>
//         {loading ? (
//           <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
//         ) : (
//           <i className="me-2 bi bi-arrow-clockwise"></i>
//         )}
//         Refresh
//       </button>
//       <div className="btn-group dropdown">
//         <button
//           className="btn dropdown-toggle"
//           data-bs-toggle="dropdown"
//           data-bs-target="#locations">
//           {authCtx.authLocations.find(item => item.collection === currentId).name}
//         </button>
//         <div id="locations" className="dropdown-menu">
//           {authCtx.authLocations.map((location) => (
//             <Fragment key={location.id}>
//               {location.collection !== currentId && (
//                 <button
//                   className="dropdown-item"
//                   onClick={() => {
//                     setCurrentId(location.collection)
//                     getOperationalHours()
//                   }}
//                 >
//                   {location.name}
//                 </button>
//               )}
//             </Fragment>
//           ))}
//         </div>
//       </div>
//     </div>
//     <input
//       onChange={onChangeCurrentTime}
//       type="date"
//       className="form-control"
//       value={moment(currentTime).format("yyyy-MM-DD")}
//       max={moment(new Date()).format("yyyy-MM-DD")}
//     />
//     <button onClick={() => getOperationalHours()} className="btn">
//       <i className="bi bi-caret-right me-2"></i>Go To Date
//     </button>
//   </div>
// </div>
// <hr /> 